<template>
    <div>
 <section class="breadcrumb-outer text-center bg-orange">
        <div class="container">
                <h2 data-aos="zoom-in-up">{{ $t('Header.AboutUs.Center') }}</h2>
        </div>
    </section>
    <section class="about-us">
        <div class="container">
            <div class="about-main">
                <div class="about-main-content">
                    <div class="row">
                    <div class="col-md-6">
                <div class="about-image" data-aos="zoom-in-right">
                <vue-plyr style="border: 5px solid #3498DB;" >
                <div class="plyr__video-embed">
                <iframe
                src="https://youtu.be/MiMHTV8KFPI"
                allowfullscreen
                allowtransparency
                allow="autoplay"
                ></iframe>
                </div>
                </vue-plyr>
                </div>
                        </div>
                         <div data-aos="zoom-in-up" class="col-md-6 col-sm-12">
                            <div class="about-content">
                                <p  v-if="$i18n.locale == 'ru'">В целях реализации инициатив и предложений, выдвинутых Республикой Узбекистан на заседании Совета глав государств-членов Шанхайской организации сотрудничества 9 июня 2017 года в г.Астана, а также всемерного использования механизмов народной дипломатии для успешного осуществления задач Стратегии действий по пяти приоритетным направлениям развития Республики Узбекистан в 2017-2021 годах и во исполнении Постановления Президента Узбекистана от 26 июня 2018 года №ПП-3807 “О мерах по государственной поддержке создания Центра народной дипломатии Шанхайской организации сотрудничества в Узбекистане” учрежден Центр народной дипломатии Шанхайской организации сотрудничества (ШОС) Узбекистана.</p>
                                 <p  v-if="$i18n.locale == 'en'">In order to implement the initiatives and proposals put forward by the Republic of Uzbekistan at the meeting of the Council of Heads of State of the Shanghai Cooperation Organization on June 9, 2017 in Astana, as well as the full use of the mechanisms of people's diplomacy for the successful implementation of the objectives of the Strategy of Action on the five priority areas of Development of the Republic of Uzbekistan in 2017-2021 and in the implementation of the Decree of the President of Uzbekistan dated June 26, 2018 No. PP-3807 “On measures for state support for the creation of the Center for People's Diplomacy of the Shanghai Cooperation Organization in Uzbekistan”, the Center for People's Diplomacy of the Shanghai Cooperation Organization (SCO) of Uzbekistan was established.</p>
                                  <p  v-if="$i18n.locale == 'cyril'">Шанхай ҳамкорлик ташкилоти Давлат раҳбарлари Кенгашининг 2017 йил 9 июндаги Остонада бўлиб ўтган йиғилишида Ўзбекистон Республикаси томонидан илгари сурилган ташаббус ва таклифларни амалга ошириш мақсадида, шунингдек, 2017-2021 йилларда Ўзбекистон Республикаси ривожланишининг бешта устувор йўналишлари бўйича ҳаракат стратегияси мақсадларини муваффақиятли амалга ошириш ва Ўзбекистон Президентининг 2018 йил 26 июндаги № Фармонини амалга ошириш учун халқ дипломатияси механизмларидан тўлиқ фойдаланиш. ПҚ-3807 "Ўзбекистонда Шанхай ҳамкорлик ташкилоти Халқ дипломатияси марказини ташкил етишни давлат томонидан қўллаб-қувватлаш чора-тадбирлари тўғрисида", Ўзбекистон Шанхай ҳамкорлик ташкилоти (ШҲТ) Халқ дипломатияси маркази ташкил етилди.</p>
                                   <p  v-if="$i18n.locale == 'uz'">Shanxay hamkorlik tashkilotiga a'zo davlatlar rahbarlari Kengashining 2017-yil 9-iyundagi Ostona shahrida bo'lib o'tgan majlisida O'zbekiston Respublikasi tomonidan ilgari surilgan tashabbus va takliflarni amalga oshirish maqsadida, shuningdek, 2017-2021-yillarda O'zbekiston Respublikasini rivojlantirishning beshta ustuvor yo'nalishi bo'yicha harakatlar strategiyasi vazifalarini muvaffaqiyatli amalga oshirish uchun hamda O'zbekiston Prezidentining 2018-yil 26-iyundagi "O'zbekistonda Shanxay hamkorlik tashkiloti xalq diplomatiyasi markazini tashkil etishni davlat tomonidan qo'llab-quvvatlash chora-tadbirlari to'g'risida" gi PQ-3807-sonli qarori ijrosini ta'minlash maqsadida O'zbekiston Shanxay hamkorlik tashkiloti (SHHT) xalq diplomatiyasi markazi tashkil etildi.</p>
                            </div>
                        </div>
                        <div data-aos="zoom-in-up" class="col-md-12 col-sm-12">
                            <div class="about-content">
                                <p  v-if="$i18n.locale == 'ru'">Центр является негосударственной некоммерческой организацией. Его учредителями являются Комитет по межнациональным отношениям и дружественным связям с зарубежными странами при Кабинете Министров Республики Узбекистан, Министерство иностранных дел, Министерство культуры, Министерство высшего и среднего специального образования, Министерство физической культуры и спорта, Государственный комитет по развитию туризма, Академия наук, Академия художеств, Союз молодежи, Творческий союз журналистов и Союз писателе </p>
                                 <p  v-if="$i18n.locale == 'en'">The Center is a non-governmental, non-profit organization. Its founders are the Committee on Interethnic Relations and Friendly Relations with Foreign Countries under the Cabinet of Ministers of the Republic of Uzbekistan, the Ministry of Foreign Affairs, the Ministry of Culture, the Ministry of Higher and Secondary Special Education, the Ministry of Physical Culture and Sports, the State Committee for Tourism Development, the Academy of Sciences, the Academy of Arts, the Youth Union, the Creative Union of Journalists and the Union of Writers..</p>
                                  <p  v-if="$i18n.locale == 'cyril'">Марказ-нодавлат, нотижорат ташкилот. Унинг таъсисчилари Ўзбекистон Республикаси Вазирлар Маҳкамаси ҳузуридаги миллатлараро муносабатлар ва хорижий давлатлар билан дўстона алоқалар бўйича қўмита, ташқи ишлар вазирлиги, Маданият вазирлиги, Олий ва Ўрта махсус таълим вазирлиги, жисмоний Маданият ва Sport вазирлиги, туризмни ривожлантириш Давлат қўмитаси, Фанлар Академияси, санъат Академияси, ёшлар Иттифоқи, журналистлар ижодий уюшмаси ва Ёзувчилар уюшмаси..</p>
                                   <p  v-if="$i18n.locale == 'uz'">Markaz nodavlat notijorat tashkilot hisoblanadi. O'zbekiston Respublikasi Vazirlar Mahkamasi huzuridagi millatlararo munosabatlar va xorijiy mamlakatlar bilan do'stlik aloqalari qo'mitasi, tashqi ishlar vazirligi, madaniyat vazirligi, oliy va o'rta maxsus ta'lim vazirligi, jismoniy tarbiya va sport vazirligi, turizmni rivojlantirish davlat qo'mitasi, fanlar Akademiyasi, badiiy Akademiya, yoshlar Ittifoqi, jurnalistlar ijodiy uyushmasi va yozuvchilar uyushmasi uning muassislari hisoblanadi..</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="about-sep mar-top-10">
                    <div class="row">
                        <div data-aos="zoom-in-up" class="col-md-12 col-sm-12">
                            <div class="about-content">
                                <p  style="font-weight: 700; font-size: 24px;" v-if="$i18n.locale == 'ru'"> Основными задачами и направлениями деятельности Центра народной дипломатии Шанхайской организации сотрудничества определены:</p>
                                 <p  style="font-weight: 700; font-size: 24px;" v-if="$i18n.locale == 'en'"> The main tasks and activities of the Center for People's Diplomacy of the Shanghai Cooperation Organization are defined as:</p>
                                  <p  style="font-weight: 700; font-size: 24px;" v-if="$i18n.locale == 'cyril'"> Шанхай ҳамкорлик ташкилоти Халқ дипломатияси марказининг асосий вазифалари ва фаолияти белгиланган:</p>
                                   <p  style="font-weight: 700; font-size: 24px;" v-if="$i18n.locale == 'uz'">Shanxay hamkorlik tashkiloti xalq diplomatiyasi markazining asosiy vazifalari va faoliyat yo'nalishlari etib quyidagilar belgilangan:</p>
                            </div>
                        </div>
                        <div data-aos="zoom-in-up" class="col-md-12 col-sm-12">
                            <div class="about-content">
                                <p v-if="$i18n.locale == 'ru'"> <i class="fa fa-check-circle"></i>содействие укреплению взаимного доверия и добрососедства, межнационального и межконфессионального согласия, развитию межцивилизационного диалога среди стран ШОС;</p>
                                 <p v-if="$i18n.locale == 'en'"> <i class="fa fa-check-circle"></i>promotion of mutual trust and good-neighborliness, interethnic and interfaith harmony, development of inter-civilizational dialogue among the SCO countries;</p>
                                  <p v-if="$i18n.locale == 'cyril'"> <i class="fa fa-check-circle"></i>ўзаро ишонч ва яхши қўшничилик, миллатлараро ва динлараро тотувликни тарғиб қилиш, ШҲТ давлатлари ўртасида сивилизациялараро мулоқотни ривожлантириш;</p>
                                   <p v-if="$i18n.locale == 'uz'"> <i class="fa fa-check-circle"></i>SHHT mamlakatlari o'rtasida o'zaro ishonch va yaxshi qo'shnichilik, millatlararo va konfessiyalararo totuvlikni mustahkamlashga, o'zaro muloqotlarni rivojlantirishga ko'maklashish;</p>
                            </div>
                        </div>
                        <div data-aos="zoom-in-up" class="col-md-12 col-sm-12">
                            <div class="about-content">
                                <p v-if="$i18n.locale == 'ru'"><i class="fa fa-check-circle"></i>участие в расширении культурно-гуманитарных связей со странами ШОС, организации взаимных визитов делегаций;</p>
                                <p v-if="$i18n.locale == 'en'"><i class="fa fa-check-circle"></i>participation in the expansion of cultural and humanitarian ties with the SCO countries, organization of mutual visits of delegations;</p>
                                <p v-if="$i18n.locale == 'cyril'"><i class="fa fa-check-circle"></i>ШҲТ мамлакатлари билан маданий-гуманитар алоқаларни кенгайтириш, делегацияларнинг ўзаро ташрифларини ташкил етишда иштирок етиш;</p>
                                <p v-if="$i18n.locale == 'uz'"><i class="fa fa-check-circle"></i>SHHT mamlakatlari bilan madaniy-gumanitar aloqalarni kengaytirish, delegatsiyalarning o'zaro tashriflarini tashkil etishda ishtirok etish;</p>
                            </div>
                        </div>
                            <div data-aos="zoom-in-up" class="col-md-12 col-sm-12">
                            <div class="about-content">
                                 <p v-if="$i18n.locale == 'ru'"><i class="fa fa-check-circle"></i>создание условий для установления дружественной атмосферы взаимодействия между институтами гражданского общества, в том числе молодежными и женскими организациями стран ШОС;</p>
                                <p v-if="$i18n.locale == 'en'"><i class="fa fa-check-circle"></i>creating conditions for establishing a friendly atmosphere of interaction between civil society institutions, including youth and women's organizations of the SCO countries;</p>
                                <p v-if="$i18n.locale == 'cyril'"><i class="fa fa-check-circle"></i>ШҲТ мамлакатлари фуқаролик жамияти институтлари, жумладан, ёшлар ва хотин-қизлар ташкилотлари ўртасида ўзаро дўстона муҳит ўрнатиш учун шарт-шароитлар яратиш;</p>
                                <p v-if="$i18n.locale == 'uz'"><i class="fa fa-check-circle"></i>fuqarolik jamiyati institutlari, shu jumladan, SHHT mamlakatlari yoshlar va xotin-qizlar tashkilotlari o'rtasida do'stona hamkorlik muhitini yaratish uchun shart-sharoitlar yaratish;</p>
                            </div>
                        </div>
                        <div data-aos="zoom-in-up" class="col-md-12 col-sm-12">
                            <div class="about-content">
                                 <p v-if="$i18n.locale == 'ru'"><i class="fa fa-check-circle"></i>содействие развитию сотрудничества между странами ШОС в информационной сфере, формирование информационных ресурсов и взаимодействие со средствами массовой информации с целью широкого разъяснения целей, задач, приоритетов деятельности и основополагающих принципов ШОС, подготовка и регулярная публикация информационно-аналитических материалов о достижениях стран ШОС в культурно-гуманитарной сфере;</p>
                                <p v-if="$i18n.locale == 'en'"><i class="fa fa-check-circle"></i>promotion of cooperation between the SCO countries in the information sphere, formation of information resources and interaction with the mass media in order to widely explain the goals, objectives, priorities and fundamental principles of the SCO, preparation and regular publication of information and analytical materials on the achievements of the SCO countries in the cultural and humanitarian sphere;</p>
                                <p v-if="$i18n.locale == 'cyril'"><i class="fa fa-check-circle"></i>ШҲТ мамлакатлари ўртасида ахборот соҳасидаги ҳамкорликни кучайтириш, ахборот ресурсларини шакллантириш ва Шҳтнинг мақсад, вазифалари, устуворликлари ва асосий тамойилларини кенг тушунтириш, ШҲТ мамлакатларининг маданий-гуманитар соҳадаги ютуқларига оид ахборот-таҳлилий материалларни тайёрлаш ва мунтазам чоп етиш мақсадида оммавий ахборот воситалари билан ўзаро ҳамкорлик;</p>
                                <p v-if="$i18n.locale == 'uz'"><i class="fa fa-check-circle"></i>SHHT mamlakatlari o'rtasida axborot sohasida hamkorlikni rivojlantirishga ko'maklashish, ShHTning maqsadlari, vazifalari, faoliyatining ustuvor vazifalari va asosiy tamoyillarini keng tushuntirish maqsadida axborot resurslarini shakllantirish va ommaviy axborot vositalari bilan hamkorlik qilish, ShHT davlatlarining madaniy-gumanitar sohadagi yutuqlari to'g'risida axborot-tahliliy materiallarni tayyorlash va muntazam chop etish;</p>
                            </div>
                        </div>
                        <div data-aos="zoom-in-up" class="col-md-12 col-sm-12">
                            <div class="about-content">
                                <p v-if="$i18n.locale == 'ru'"><i class="fa fa-check-circle"></i>применение инструментов народной дипломатии с целью сближения стран ШОС и их народов, укрепления духа взаимного доверия и добрососедства.</p>
                                <p v-if="$i18n.locale == 'en'"><i class="fa fa-check-circle"></i>the use of public diplomacy tools to bring the SCO countries and their peoples closer together, to strengthen the spirit of mutual trust and good-neighborliness.</p>
                                <p v-if="$i18n.locale == 'cyril'"><i class="fa fa-check-circle"></i>ШҲТ давлатлари ва уларнинг халқларини бир-бирига яқинлаштириш, ўзаро ишонч ва яхши қўшничилик руҳини мустаҳкамлаш учун жамоат дипломатияси воситаларидан фойдаланиш.</p>
                                <p v-if="$i18n.locale == 'uz'"><i class="fa fa-check-circle"></i>SHHT mamlakatlari va ularning xalqlarini yaqinlashtirish, o'zaro ishonch va yaxshi qo'shnichilik ruhini mustahkamlash maqsadida xalq diplomatiyasi vositalarini qo'llash.</p>
                            </div>
                        </div>
                         <div data-aos="zoom-in-up" class="col-md-12 col-sm-12">
                            <div class="about-content">
                                  <p v-if="$i18n.locale == 'ru'"><i class="fa fa-check-circle"></i>Центр находится по адресу: г.Ташкент, Яккасарайский район, улица Бабура, 45.</p>
                                <p v-if="$i18n.locale == 'en'"><i class="fa fa-check-circle"></i>The center is located at 45 Babur Street, Yakkasarai district, Tashkent.</p>
                                <p v-if="$i18n.locale == 'cyril'"><i class="fa fa-check-circle"></i>Марказ Тошкент шаҳри, Яккасарой тумани Бобур кўчаси 45 уйда жойлашган.</p>
                                <p v-if="$i18n.locale == 'uz'"><i class="fa fa-check-circle"></i>Markaz Toshkent shahri, Yakkasaroy tumani, Bobur ko'chasi, 45-uyda joylashgan.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </div>
</template>
<script>
import VuePlyr from 'vue-plyr'
export default {
    name: 'Video',
    components:{
      VuePlyr
    },
      methods: {
},
  data() {
return{
}
  },
}
</script>
<style scoped>
.bg-orange{
    margin: 0;
    background-color: #3498DB !important;
    background-image: none;
}
.breadcrumb-outer {
    padding: 40px 0 !important;
    position: relative;
}
.breadcrumb-outer h2{
    padding: 60px 0 10px !important;
}
.d-flex{
    display: flex;
    flex-direction: column;
}
p i{
    margin-right: 6px;
    font-size: 22px;
    color: #5d5c5c;
}
.video-embed{
    height: 300px;
}
p {
    font-weight: 300;
    line-height: 2;
    margin-bottom: 17px;
    color: #5d5c5c;
    font-size: 15px;
}
@media(max-width: 400px){
  .breadcrumb-outer h2 {
    padding: 100px 0 15px !important;
  }
}
</style>